import React, { useState, useEffect } from 'react';
import './App.css';
import axios from 'axios';

function NeuronView({ neuronData, isNegative }) {
  const [showPositive, setShowPositive] = useState(!isNegative);
  const [activations, setActivations] = useState(null);
  const [trueActivations, setTrueActivations] = useState(null);
  const [explanationIndex, setExplanationIndex] = useState(0);

  const [multiplier, setMultiplier] = useState(0);
  const [trueMultiplier, setTrueMultiplier] = useState(0);

  const [customExplanation, setCustomExplanation] = useState('');
  const [customActivations, setCustomActivations] = useState(null);
  const [customScore, setCustomScore] = useState(0);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const computeColor = (float) => {
    let MIN_COLOR = null;
    let MAX_COLOR = null;
    if (!showPositive) {
      MIN_COLOR = [248, 117, 87, 0];
      MAX_COLOR = [255, 0, 0, 1];
    } else {
      MAX_COLOR = [118, 135, 250, 0.96];
      MIN_COLOR = [174, 196, 245, 0];
    }
    return `rgba(${MIN_COLOR.map((c, i) => c + (MAX_COLOR[i] - c) * float).join(',')})`;
  }


  const submitExplanation = async () => {
    if (!neuronData.new) {
      return;
    }
    setIsSubmitting(true);

    try {
      const response = await axios.post('https://server.neuron-descriptions.net/simulate', {
        explanation: customExplanation,
        layer: neuronData['layer'],
        neuron: neuronData['neuron'],
        sign: showPositive ? "+" : "-",
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      console.log(response.data);
      setCustomActivations(response.data.simulated_activations);
      setCustomScore(response.data.score);
    } catch (error) {
      console.error('Error submitting explanation:', error);
      // Handle the error appropriately, e.g., show a user-friendly message
    } finally {
      setIsSubmitting(false);
    }
  }

  useEffect(() => {
    if (explanationIndex === -1) {
      if (!customActivations) return;
      setActivations(customActivations);
      setMultiplier(1/Math.max(...customActivations.flat(Infinity)));
      return;
    }
    if (!neuronData) return;
    setActivations(neuronData.simulated_activations[explanationIndex]);
    setMultiplier(1/Math.max(...neuronData.simulated_activations[explanationIndex].flat(Infinity)));
    console.log(neuronData.simulated_activations[explanationIndex]);
  }, [neuronData, showPositive, explanationIndex, customActivations])

  useEffect(() => {
    if (!neuronData) return;
      let arr = [];
      for (let i = 0; i < neuronData.exemplar_tokens.length; i++) {
        arr.push(neuronData.exemplar_tokens[i][1]);
      }
      setTrueActivations(arr);
      setTrueMultiplier(1/Math.max(...arr.flat(Infinity)));
  }, [neuronData, showPositive])

  return (
    <>{activations && (
        <div>
        <div style={{ fontSize: '16px', fontWeight: 500, margin: '10px' }}>Layer {neuronData['layer']}, Neuron {neuronData['neuron']}, {showPositive ? 'Positive' : 'Negative'} Activations</div>
        <div style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto'  }}>
            <div style={{ marginBottom: '20px', width: '100%' }}>
                <table style={{borderCollapse: 'collapse', textAlign: 'left', width: '100%' }}>
                    <thead>
                        <tr>
                            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left', fontWeight: 500 }}>Explanation</th>
                            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left', fontWeight: 500 }}>Score</th>
                        </tr>
                    </thead>
                    <tbody>
                        {neuronData.explanation_summary.map((explanation, index) => (
                            <tr
                                key={index}
                                style={{
                                    backgroundColor: explanationIndex === index ? '#f2f2f2' : 'white',
                                    cursor: 'pointer'
                                }}
                                onClick={() => setExplanationIndex(index)}
                            >
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                                    {explanation[0]}
                                </td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{explanation[1].toFixed(3)}</td>
                            </tr>
                        ))}
                        <tr
                            style={{
                                backgroundColor: explanationIndex === -1 ? '#f2f2f2' : 'white',
                                cursor: 'pointer'
                            }}
                            onClick={() => setExplanationIndex(-1)}
                        >
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                                Custom Explanation: {customExplanation}
                            </td>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>{customScore.toFixed(3)}</td>
                        </tr>
                    </tbody>
                </table>
                <div style={{ textAlign: 'left' }}>
                  <div style={{ position: 'relative' }}>
                    <textarea
                      style={{
                        display: 'block',
                        width: 'calc(100% - 26px)',
                        marginTop: '5px',
                        paddingBottom: '40px', // Add space for the button
                        paddingLeft: '12px',
                        paddingRight: '12px',
                        paddingTop: '12px',
                        fontSize: '14px',
                        font: 'inherit',
                        borderRadius: '0px',
                        border: '1px solid #ddd',
                        resize: 'none',
                        outline: 'none', // Remove default focus outline
                      }}
                      onFocus={(e) => e.target.style.border = '1px solid #ccc'} // Change border color on focus
                      onBlur={(e) => e.target.style.border = '1px solid #ddd'} // Revert border color on blur
                      placeholder="Enter your custom explanation here..."
                      value={customExplanation}
                      onChange={(e) => setCustomExplanation(e.target.value)}
                    />
                    <button
                      style={{
                        position: 'absolute',
                        bottom: '5px',
                        right: '5px',
                        font: 'inherit',
                      }}
                      onClick={submitExplanation}
                      disabled={isSubmitting}
                    >
                    {isSubmitting ? "Scoring Custom Explanation..." : (neuronData.new ? "Submit Custom Explanation" : "CURRENTLY DISABLED - Submit Custom Explanation")}
                    </button>
                  </div>
                </div>
            </div>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  <th style={{ fontWeight: 500, padding: '8px', border: '1px solid #ccc' }}>True Activations</th>
                  <th style={{ fontWeight: 500, padding: '8px', border: '1px solid #ccc' }}>Simulated Activations</th>
                </tr>
              </thead>
              <tbody>
                {neuronData.exemplar_tokens.map((exemplar, exemplarIndex) => (
                  <tr key={exemplarIndex}>
                    {[0, 1].map((columnIndex) => (
                      <td key={columnIndex} style={{ border: '1px solid #ccc', padding: '10px', textAlign: 'left' }}>
                        {exemplar[0].map((token, tokenIndex) => (
                          <span
                            key={tokenIndex}
                            style={{
                              marginRight: '0px',
                              backgroundColor: `${columnIndex === 0 ? computeColor(trueMultiplier*Math.abs(trueActivations[exemplarIndex][tokenIndex])) : computeColor(multiplier*Math.abs(activations[exemplarIndex][tokenIndex]))}`
                            }}
                          >
                            {token}
                          </span>
                        ))}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
        </div>
        </div>
    )}
  </>
  );
}

export default NeuronView;
