import React, { useState } from 'react';
import './App.css';
import axios from 'axios';

import TempNeuronView from './TempNeuronView';

function Homepage() {
  const [neuronData, setNeuronData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [layerId, setLayerId] = useState(null);
  const [neuronId, setNeuronId] = useState(null);

  const isValidInt = (value) => {
    return !isNaN(value) && parseInt(value) >= 0;
  }

  const handleListFiles = async () => {
    setIsLoading(true);
    try {
      if (true) {
        //  (!isValidInt(layerId) || !isValidInt(neuronId)) {
        const response = await axios.get('https://server.neuron-descriptions.net/read_file', {
            headers: {
            'Content-Type': 'application/json',
            },
        });
        setNeuronData(response.data);
      } else {
        const response = await axios.get('https://server.neuron-descriptions.net/read_specific_file', {
          headers: {
            'Content-Type': 'application/json',
          },
          params: {
            layer: layerId,
            neuron: neuronId
          }
        });
        setNeuronData(response.data);
      }
    } catch (error) {
      console.error('Error fetching neuron data:', error);
      setNeuronData(null);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="App">
      {/* <div>
        <input
          type="number"
          value={layerId}
          onChange={(e) => setLayerId(e.target.value)}
          placeholder="(Optional) Layer number"
          min="0"
          style={{ width: '200px'}}
        />
        <input
          type="number"
          value={neuronId}
          onChange={(e) => setNeuronId(e.target.value)}
          placeholder="(Optional) Neuron number"
          min="0"
          style={{ width: '200px' }}
        />
      </div> */}
      <button onClick={handleListFiles} disabled={isLoading}>
        {isLoading ? "Getting data for neuron..." : "Get neuron data"}
      </button>

      {neuronData && (
        <TempNeuronView neuronData={neuronData} />
      )}
    </div>
  );
}

export default Homepage;
