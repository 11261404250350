import React, { useState, useEffect } from 'react';
import './App.css';
import axios from 'axios';

function TempNeuronView({ neuronData }) {
  const [showPositive, setShowPositive] = useState(false);
  const [trueActivations, setTrueActivations] = useState(null);
  const [explanationIndex, setExplanationIndex] = useState(0);

  const [trueMultiplier, setTrueMultiplier] = useState(0);

  const [customExplanation, setCustomExplanation] = useState('');
  const [customScore, setCustomScore] = useState(0);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [metadata, setMetadata] = useState('');

  const computeColor = (float) => {
    let MIN_COLOR = null;
    let MAX_COLOR = null;
    if (!showPositive) {
      MIN_COLOR = [248, 117, 87, 0];
      MAX_COLOR = [255, 0, 0, 1];
    } else {
      MAX_COLOR = [118, 135, 250, 0.96];
      MIN_COLOR = [174, 196, 245, 0];
    }
    return `rgba(${MIN_COLOR.map((c, i) => c + (MAX_COLOR[i] - c) * float).join(',')})`;
  }

  const submitExplanation = async () => {
    setIsSubmitting(true);

    try {
      const response = await axios.post('https://server.neuron-descriptions.net/simulate', {
        explanation: customExplanation,
        layer: neuronData['layer'],
        neuron: neuronData['neuron'],
        metadata: metadata,
        sign: showPositive ? "+" : "-",
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      console.log(response.data);
      setCustomScore(response.data.score);
    } catch (error) {
      console.error('Error submitting explanation:', error);
      // Handle the error appropriately, e.g., show a user-friendly message
    } finally {
      setIsSubmitting(false);
    }
  }

  useEffect(() => {
    if (!neuronData) return;
    setCustomExplanation('');
    setCustomScore(0.0);
    setShowPositive(neuronData['sign'] === '+');
    let arr = [];
      for (let i = 0; i < neuronData.exemplar_tokens.length; i++) {
        arr.push(neuronData.exemplar_tokens[i][1]);
      }
      setTrueActivations(arr);
      setTrueMultiplier(1/Math.max(...arr.flat(Infinity)));
  }, [neuronData])

  return (
    <>{trueActivations && (
        <div>
        <h3>Layer {neuronData['layer']}, Neuron {neuronData['neuron']}, {showPositive ? 'Positive' : 'Negative'} Activations</h3>
        <div style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
            <div style={{ marginBottom: '20px' }}>
            <div style={{ margin: '10px 0', textAlign: 'left' }}>
            <label>
            <input
                type="radio"
                name="explanation"
                value="custom"
                checked={explanationIndex === -1}
                onChange={() => setExplanationIndex(-1)}
            />
            Custom Explanation: {customExplanation} (Score: {customScore.toFixed(3)}):
            </label>
            <textarea
              style={{ display: 'block', width: '100%', marginTop: '5px' }}
              placeholder="Enter your custom explanation here..."
              value={customExplanation}
              onChange={(e) => setCustomExplanation(e.target.value)}
            />
            <textarea
              style={{ display: 'block', width: '100%', marginTop: '5px' }}
              placeholder="Add metadata (eg. labeller) here..."
              value={metadata}
              onChange={(e) => setMetadata(e.target.value)}
            />

            <button
            style={{ marginTop: '5px' }}
            onClick={submitExplanation}
            disabled={isSubmitting}
            >
            {isSubmitting ? "Scoring Custom Explanation..." : "Submit Custom Explanation"}
            </button>
        </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {[0].map((columnIndex) => (
            <div key={columnIndex} style={{ width: '48%' }}>
              <div style={{fontSize: '16px', fontWeight: 'bold', margin: '2px 0' }}>
                {columnIndex === 0 ? 'True' : 'Simulated'} Activations
              </div>
            <div style={{padding: '10px', margin: '10px 0', textAlign: 'left' }}>
                {neuronData.exemplar_tokens.map((exemplar, exemplarIndex) => (
                exemplarIndex < 20 && <div key={exemplarIndex} style={{ border: '0.5px solid #ccc', padding: '10px', textAlign: 'left' }}>
                    {exemplar[0].map((token, tokenIndex) => (
                    <span
                        key={tokenIndex}
                        style={{
                        marginRight: '0px',
                        backgroundColor: `${computeColor(trueMultiplier*Math.abs(trueActivations[exemplarIndex][tokenIndex]))}`
                            }}
                        >
                            {token}
                            </span>
                        ))}
                        </div>
                        ))}
                    </div>
                    </div>
                ))}
            </div>
        </div>
        </div>
    )}
  </>
  );
}

export default TempNeuronView;
